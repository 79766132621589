@import '../pages/page-include';

.product-grid-wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.product-grid-pd {
    &.product-grid-pd--5 {
        width: 20%;
        flex-basis: 20%;
    }

    &.product-grid-pd--4 {
        width: 25%;
        flex-basis: 25%;
    }

    &.product-grid-pd--3 {
        width: 33.33333%;
        flex-basis: 33.33333%;
    }

    &.product-grid-pd--2 {
        width: 50%;
        flex-basis: 50%;
    }

    @include mq($large-mobile) {
        &.product-grid-pd--5,
        &.product-grid-pd--4,
        &.product-grid-pd--3,
        &.product-grid-pd--2 {
            width: 50%;
            flex-basis: 50%;
        }
    }
}
